<template>
    <Datepicker v-model="newdate" :placeholder="placeholder" :enableTimePicker="0" :locale="lan" :weekStart="0" :autoApply="1" :format="format" textInput />
</template>

<script>
 import Datepicker from '@vuepic/vue-datepicker';
 import '@vuepic/vue-datepicker/dist/main.css'
    export default {    
        props:{
          ndate:{
            type:String,
            default:''
          },
          val:{
            type:String,
            default:''
          },
          placeholder:{
            type:String,
            default:'2022-01-01'
          },
          format:{
            type:String,
            default:'yyyy-MM-dd'
          },
        },
        data() {
            return {
                  newdate:this.val,
                  lan:localStorage.getItem('cur_lan')=='en'?'en':'ja',
                }
        },
        components: { Datepicker },
        mounted(){
          console.log(this.ndate)
        },
        watch:{
          newdate(newdate){
            console.log(newdate)
            this.$emit('changedate',newdate,this.ndate);
          },
          val(val){
            console.log(val)
            this.newdate=val;
            this.$emit('changedate',val,this.ndate);
          }
        }
    }
</script>
<style lang="scss">
.dp__main{width:200px;display:inline-block;}
.dp__input::-webkit-input-placeholder{
color:#ccc;
}
</style>